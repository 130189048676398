// ~~~ CONTENTFUL PAGE ~~~
/* eslint-disable */

import React from 'react'
import Helmet from 'react-helmet'
import Layout from 'components/Layout'
import Hero from 'components/Contentful/Hero'
import IntroAndPicture from 'components/Contentful/IntroAndPicture'
import YouTubeLink from 'components/Contentful/YouTubeLink'

const Page = props => {
  return (
    <Layout location={props.location}>
      <Helmet
        title="Referenzgeschichte Peter Gisin, Lausen BL"
        meta={[
          { name: 'description', content: 'Luft/Wasser-Wärmepumpe anstatt einer Ölheizung ➤ Erfahrungsbericht von Peter Gisin✔️ Öltank Demontage ✔️ Umbau ✔️ Hier informieren!' },
          { name: 'keywords', content: 'Video Erfahrungsbericht Öltank Demontage Luft/Wasser-Wärmepumpe' },
        ]}
      >
        
      </Helmet>
      
      <div
          className='bg__flex--white'
          key='713mIE1YMLMoE6lf6BmYu2-2wNFhtsHy2gRBZbELznljd'
          id='referenz-video-oeltank-demontage-peter-gisin-lausen-bl'
        >
          <Hero
            {...{
    "type": "hero",
    "id": "2wNFhtsHy2gRBZbELznljd",
    "updatedAt": "2023-11-17T12:17:51.249Z",
    "title": "Referenz-Video Öltank Demontage: Peter Gisin, Lausen BL",
    "size": "Groß",
    "teaser": "Gisins aus Lausen BL berichten, weshalb sie sich vom 15'500 Liter fassenden Öltank getrennt haben und wie der Umbau abgelaufen ist. Auch die involvierten und lokalen Installationsfirmen kommen zum Wort und erzählen, wieso sie die Zusammenarbeit mit der Heizungsmacher AG besonders schätzen.",
    "image": {
        "description": "Haus Umbau Luft/Wasser Wärmepumpe in Lausen BL",
        "contentType": "image/jpeg",
        "url": "//images.ctfassets.net/wbm1kpreb3m8/5PTC7WDw3yIhtvxOq6lZY3/22a7d3602418f8938379fbef4a3cb1fd/Drone3__1_.jpg"
    },
    "links": [
        {
            "type": "link",
            "id": "2HoGCnl720mMqmQ108H1F7",
            "updatedAt": "2023-07-25T06:25:06.308Z",
            "label": "Zur Referenz-Übersicht",
            "url": "https://www.heizungsmacher.ch/referenzen/"
        }
    ],
    "anchorName": "referenz-video-oeltank-demontage-peter-gisin-lausen-bl"
}} location={props.location}
          />
        </div>
<div
          className='bg__flex--grey'
          key='713mIE1YMLMoE6lf6BmYu2-69fwAXDkj5qnZmy6vPDlv'
          id='wieso-heizungsmacher-kunde-peter-gisin-und-die-lokalen-installateure'
        >
          <IntroAndPicture
            {...{
    "type": "introAndPicture",
    "id": "69fwAXDkj5qnZmy6vPDlv",
    "updatedAt": "2023-11-16T15:32:35.047Z",
    "title": "Wieso Heizungsmacher? Kunde Peter Gisin und die lokalen Installateure erzählen.",
    "anchorName": "wieso-heizungsmacher-kunde-peter-gisin-und-die-lokalen-installateure",
    "text": "<p>Im Baselland im Dorf Lausen wurde im Herbst 2023 eine Ölheizung durch eine Luft/Wasser Wärmepumpe ersetzt und aus dem ehemaligen Tankraum ein neuer Kellerraum geschaffen. Der Bauherr berichtet, dass der geplante Terminplan eingehalten und der Umbau reibungslos durchgeführt werden konnte.</p>\n<p>Dies ist nicht selbstverständlich. Bei einem solch komplexen Bauvorhaben ist es wichtig, dass alle involvierten Parteien gut aufeinander abgestimmt sind und qualitativ gute Arbeit leisten. Die Firma Heizungsmacher AG bildet dabei die Drehscheibe zwischen allen Parteien, stimmt diese aufeinander ab und begleitet den Kunden während des ganzen Ablaufs. Unter anderem ist der lokale Elektriker für das Bauprovisorium verantwortlich. Der Heizungsinstallateur legt die Heizung stillt, organisiert das Boilerprovisorium und installiert die neue Wärmepumpe. Der Baumeister kommt in den Genuss, sein ganzes Spektrum an Arbeiten zu präsentieren. Dies beinhaltet Arbeiten wie das Ausfräsen eines Türausschnittes, das Legen eines Leitungsgrabens oder das Erstellen eines Sockels für die Wärmepumpe.</p>\n<p>In diesem Video berichten einige der involvierten Parteien über Ihre Erfahrungen im Zusammenhang mit dem Heizungsersatz-Projekt bei Gisins in Lausen.</p>\n",
    "image": {
        "description": "Geschäftsmodell Heizungsmacher",
        "contentType": "image/svg+xml",
        "url": "//images.ctfassets.net/wbm1kpreb3m8/30q540qZmsbmx274nwzdea/3421f9d00f4155ab427b19926b7f6e78/Icon_Gesch__ftsmodell_Vector_copy-01.svg"
    }
}} location={props.location}
          />
        </div>
<div
          className='bg__flex--white'
          key='713mIE1YMLMoE6lf6BmYu2-6KSm4OasnxtaeoszRVnzWk'
          id='referenz-video-peter-gisin-und-heizungsmacher-ag-erzaehlen'
        >
          <YouTubeLink
            {...{
    "type": "youTubeLink",
    "id": "6KSm4OasnxtaeoszRVnzWk",
    "updatedAt": "2023-11-16T15:07:14.156Z",
    "title": "Öltank Demontage Referenz-Video - Peter Gisin und Heizungsmacher AG erzählen",
    "anchorName": "referenz-video-peter-gisin-und-heizungsmacher-ag-erzaehlen",
    "link": "https://www.youtube.com/watch?v=SuSzxE2WwmM",
    "videoId": "SuSzxE2WwmM",
    "start": null,
    "description": ""
}} location={props.location}
          />
        </div>
<div
          className='bg__flex--grey'
          key='713mIE1YMLMoE6lf6BmYu2-5jl6yjDwGax05p7DpzlEBw'
          id='wuenschen-sie-eine-beratung'
        >
          <IntroAndPicture
            {...{
    "type": "introAndPicture",
    "id": "5jl6yjDwGax05p7DpzlEBw",
    "updatedAt": "2023-08-30T09:41:16.098Z",
    "title": "Wünschen Sie eine Beratung?",
    "anchorName": "wuenschen-sie-eine-beratung",
    "text": "<p>Sie interessieren sich für eine Wärmepumpe und sind noch nicht sicher, ob dies die richtige Wahl ist? Besuchen Sie unseren kostenlosen Heizungs-Check oder lassen Sie sich von unseren <a href=\"https://www.heizungsmacher.ch/ueber-uns/\" title=\"ueber-uns\">Heizexperten</a> beraten.</p>\n",
    "link": {
        "type": "link",
        "id": "7p7eaG5GRI8A1fElOkNxIb",
        "updatedAt": "2022-06-28T08:23:33.929Z",
        "label": "Online Heizungs-Check",
        "url": "https://www.heizungsmacher.ch/konfigurator"
    }
}} location={props.location}
          />
        </div>
    </Layout>
  )
}

export default Page
